import React from "react";
import { FaPhone } from "react-icons/fa";

const NeedHelp = () => {
  return (
    <div className="footer-banner">
      <span className="footer-banner-text">
        <span className="full-text">Need Assistance? Dial</span>
        <FaPhone className="phone-icon" />
        <a href="tel:(888) 788-0417" className="phone-number text-white">
          (888) 788-0417
        </a>
      </span>
    </div>
  );
};

export default NeedHelp;
