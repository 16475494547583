import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container policy-container my-5 py-5">
      <h2 className="policy-header">Privacy Policy</h2>
      <p className="policy-intro">
        <strong>Please note:</strong> The privacy policy may be provided in
        several languages to ease your understanding of its terms depending on
        your country of residence. However, the legally binding version is the
        English version.
      </p>
      <p>
        The titles and headlines are only for convenience purposes and are not
        legally binding.
      </p>
      <p>
        This Privacy Policy (the “Policy”) describes the data collection and
        processing practices of NETGET ROUTER Software SAS and its subsidiaries
        and Affiliated Companies (“NETGET ROUTER,” “we,” “us,” or “our”). The
        Policy explains what data we collect, why we collect it, and how we use
        it when you access our websites that link to this Policy (each, a
        “Website” and jointly the “Websites”), download or use one or more of
        our software products (“Product(s)”) and using or accessing any related
        services (together with the Websites and Products, the “Services”).
      </p>

      <h3 className="policy-title">Introduction</h3>
      <p>
        We recognize the importance of delivering safe products and protecting
        the privacy rights of our customers. Therefore, we designed this Policy
        in clear language and modular structure.
      </p>
      <p>
        This Policy forms part of your agreement with us and shall be read and
        consented to together with the terms of service and/or license agreement
        of the relevant Service.
      </p>
      <p>
        Please read the Policy carefully to be sure you understand it and agree
        with its terms before you use any of the Services. If you do not agree
        with the terms in this Policy and the choices, we provide do not
        mitigate your concerns, please do not access or use the Services. For
        any questions or matter related to this Policy, or your privacy rights,
        please contact our data protection officer at:{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>.
      </p>

      <h3 className="policy-title">General Provisions</h3>

      <h4>1. Jurisdiction and language</h4>
      <p>
        Please note that this Policy is designed with the EU General Data
        Protection Regulation (GDPR) and the ePrivacy directive in mind
        (jointly, the “Applicable Laws”). If you reside in any jurisdiction
        other than the EU, the EEA, or the UK, and if you would like to learn
        more about our region-specific processing of Personal Information, you
        can directly navigate to the supplemental applicable jurisdictions
        notices below.
      </p>
      <p>
        The Policy may be provided in several languages to ease your
        understanding of its terms depending on your country of residence.
        However, the legally binding version is the English version.
      </p>

      <h4>2. Scope and applicability</h4>
      <h5>2.1. Controller Information</h5>
      <p>
        NETGET ROUTER Software SAS is the data controller of the Personal
        Information provided that are processed in connection with the Services.
        The postal address is 15001 90th Ave, Jamaica, NY 11432.
      </p>
      <p>
        We can be contacted at:{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>{" "}
        for general inquiries, or at{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>{" "}
        for privacy-related inquiries.
      </p>

      <h5>2.2. Data Protection Officer (DPO)</h5>
      <p>
        If you wish to contact us in any matter concerning this Policy, you are
        welcome to do so through the above address or by email to our data
        protection officer (DPO) at:{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>.
      </p>

      <h5>2.3. Categories of Data Subjects</h5>
      <p>This Policy applies to the following types of Data Subjects:</p>
      <ul>
        <li>
          a visitor to a Website, including a Products webpage or landing page,
          that links to this Policy or to this Privacy Portal (“Visitor(s)”);
          and/or
        </li>
        <li>
          a user of any of the Products who downloads and installs a Product or
          otherwise interacts with the Services, whether accessing, downloading,
          installing, consuming, or otherwise using them (“User(s)”); and/or
        </li>
        <li>
          a business customer, a business partner that has a contractual
          relationship with us, or a prospective customer or partner that is yet
          to be engaged in a contract with us (“Customer(s)”); and/or
        </li>
        <li>a vendor, supplier, or a Processor (“Vendor(s)”).</li>
      </ul>
      <p>
        Unless otherwise mentioned in this Policy, we refer to any type of Data
        Subject as “you”.
      </p>
      {/* ----- */}
      <h3 class="policy-title">Changes to this Policy</h3>
      <p class="policy-text">
        From time to time, we may amend, add, or delete provisions of this
        Policy to accommodate changes in our Services, Products, and data
        processing policies and to conform to new legislation. If we introduce
        such changes, we will post the amended Policy in this Portal and the
        website of the specific Product or Service, and the changes will be
        reflected in the “last updated” date stipulated in the Policy.
      </p>
      <p class="policy-text">
        In case of a substantial change, we will provide you with a more
        prominent notice, for example, posting a note on the Website or sending
        an email to you, if we hold your email address. Your use of the Site or
        Services following the effective date of any such modification means you
        accept and agree to be bound by the modified Policy.
      </p>

      <h3 class="policy-title">Transparency, Consent, and Choice</h3>
      <p class="policy-text">
        You are not legally obligated to provide us with Personal Information
        (see definition below), and you acknowledge that providing us with
        Personal Information is done freely by you. You also acknowledge that we
        will not be able to provide you with the relevant Services or process
        requests you make without such information. Some of the Services will
        require you to submit specific Personal Information in addition to other
        information already processed about you. In such a case, you can choose
        whether you want to use such additional Services or not.
      </p>
      <p class="policy-text">
        To better learn about your rights relating to your Personal Information,
        please refer below to the relevant section in this Policy.
      </p>

      <h3 class="policy-title">Personal Information We May Process and How</h3>
      <p class="policy-text">
        The information we process in connection with the Services can be one of
        the following:
      </p>
      <p class="policy-text">
        <strong>Personal Information (PI)</strong> means information that
        identifies you as a natural person, or that may be used, either alone or
        in combination with other information, to personally identify you as a
        natural person. Such information may include, for example, a first and
        last name, an email address, a phone number, a home or other physical
        address, and other contact information. In addition, in some
        jurisdictions (such as the EU, for example), and given the specific use
        we make with the information, an IP address, device ID, and cookies
        could be considered as Personal Information as well.
      </p>
      <p class="policy-text">
        <strong>Non-Personal Information (non-PI)</strong> means information
        that cannot personally identify or lead to identifying a natural person.
        For example, statistics or aggregated information, or any other type of
        data that can no longer be attributed to you.
      </p>
      <p class="policy-text">
        This Policy applies to Personal Information and can be processed or
        derived in one of the following ways:
      </p>

      <h4 class="policy-subtitle">Information You Submit to Us</h4>
      <p class="policy-text">
        For example: forms you submit, accounts you open, or information you
        share when you actively contact us via one or more of the channels we
        may make available to you.
      </p>
      <p class="policy-text">
        Submitting information to us is optional in certain cases and required
        in others, depending on the Services you choose to use or access, and
        whether you are a Customer or a User. This category includes one or more
        of the following data categories:
      </p>

      <h5 class="policy-subsubsection">01. Contact Details</h5>
      <p class="policy-text">
        We may process your contact details (such as name, email address, or
        phone number) in case you submitted those to us when you contact us,
        open an account with us, when you subscribe to our mailing list, or when
        reaching out to our support services, as further described below.
      </p>

      <h5 class="policy-subsubsection">02. Subscription to Our Mailing List</h5>
      <p class="policy-text">
        If you voluntarily subscribe to our email communications, you will be
        asked to provide us with your email address. You can unsubscribe at any
        time using the unsubscribe option within the body of the email sent to
        you or by contacting our DPO at{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>.
      </p>
      <p class="policy-text">
        <strong>Purpose:</strong> We use your email address to send you
        information related to the Products and Services, provide you with tips
        related to our Services, and, subject to your consent, send promotional
        and marketing emails.
      </p>
      <p class="policy-text">
        <strong>Retention:</strong> We will retain your information for as long
        as you do not explicitly ask us to delete it or if we learn that it was
        unlawfully collected (for example, if we discover you are a minor), and
        provided that no exemption is applied according to applicable law.
      </p>

      <h5 class="policy-subsubsection">
        03. Signing Up, Registration, and Account Management
      </h5>
      <p class="policy-text">
        Some of the Services require registration and signing up to a user
        account, for example, when you use any of our trial software licenses or
        when you choose to purchase a Product license. If you choose to do so,
        you will be asked to submit some information such as your email address,
        full name, and other Personal Information as the submission form
        indicates.
      </p>
      <p class="policy-text">
        Your email address will be used as your username together with a
        password of your choice for authentication and authorization purposes.
        This information will be used to uniquely connect your account and
        purchases with you, your device, and your usage, and for account
        authentication and security purposes. Upon your consent, your email
        address will also be used to send you information, updates, and
        newsletters about our Services.
      </p>
      <p class="policy-text">
        <strong>Purpose:</strong> We use your account information to assign an
        account to you, secure your access to your account, manage your
        installations and purchases of license activity, and provide you with
        access to your account and the ability to manage it directly.
      </p>
      <p class="policy-text">
        <strong>Retention:</strong> We retain account information for as long as
        you are a User of the Product, and so long as the Account Information is
        required to provide you with the Services or process your payments,
        billing, complete a transaction, or as otherwise required by any
        applicable law. If billing is involved, we may retain such data for up
        to seven (7) years from the billing event, as required by applicable tax
        laws.
      </p>

      {/* ------------------ */}

      <div className="content-section">
        <h4>04. Purchasing a product license.</h4>
        <p>
          If you wish to purchase a product license from us, you will be asked
          to provide your contact information, such as your full name, email
          address, billing information, shipping information (if relevant), and
          any other purchase-related information, in order to process your
          purchase, issue a license key for you, contact you in case of support
          necessity if an issue arises, and to protect the access to your
          account and purchased Product licenses. We also use this information
          to deliver purchase confirmations, notifications, download links,
          activation keys, support and product notices, and other
          Product-related needs.
        </p>
        <p>
          Upon your requested payment method, certain payment information may be
          further processed ("Payment Information"). Please note that depending
          on the product you purchase and the payment method you select, your
          Payment Information will be collected either by our own payment
          processing services or by our third-party payment processors. Payment
          Information is collected and processed by a NetGet Router Affiliated
          Company that provides billing and payment processing services on our
          behalf. Therefore, Payment Information will be collected and stored in
          its secure facilities, certified with the strict PCI DSS certificate,
          and its privacy policy will be made available from the checkout page,
          and here.
        </p>
        <p>
          If you purchase a license via a mobile application store, the relevant
          store will process your payment directly. Your payment information
          will be used to charge you for the product by our affiliated payment
          processing services or by our third-party payment processors.
        </p>
        <p>
          <strong>Purpose:</strong> We use this information to process your
          order, to deliver the Product you ordered, whether physically or by
          sending you your license key directly to your email address, to manage
          your purchase and the license key as per your selected type of
          subscription, and to protect the access to your account and purchased
          Product licenses.
        </p>
        <p>
          We also use this information based on your consent and sometimes for
          our legitimate interest of assigning you a license key based on your
          specific type of subscription and to the specific type of device you
          associated with the license key. We associate your license key with
          your email address and device information. If we learn that it is
          necessary to prevent fraud and abusive conduct, we may add additional
          data to it. Upon your consent, your email address will also be used to
          send you information, updates, and newsletters about our services.
        </p>
        <p>
          In case there is an issue with your order, one of our representatives
          will use the contact details you submitted to reach you by email or
          phone.
        </p>
        <p>
          <strong>Retention:</strong> We retain this information for as long as
          your license key is valid, and may sometimes retain it for longer
          periods when applicable laws require (for instance, payment
          information), or if you did not withdraw your consent to share
          information with you via certain contact details you submitted to us.
        </p>

        <h4>05. Contacting us/Support.</h4>
        <p>
          When you choose to contact us, we will process your inquiry details
          and content, including your contact details, in order to address such
          inquiries, you have submitted to us. If you contact us for support,
          feedback, or other inquiries, either through an online form available
          on our Websites, by sending us an email, or by other means of
          communication we make available (such as by phone or submitting a bug
          report or filling in a survey), you will be requested to provide us
          with your name and email address (or other contact information, as the
          case may be and depending on how you want us to contact you back), and
          the subject matter of your inquiry.
        </p>
        <p>
          <strong>Purpose:</strong> We use this information, based on your
          consent and our legitimate interest, for the purpose for which it was
          provided, including responding to your inquiry and providing you with
          the support or information you have requested, monitoring status, and
          for ticketing and recording purposes. This includes addressing any
          "Help and Support" ticket or chat you submit to us via the Websites or
          Services.
        </p>
        <p>
          <strong>Retention:</strong> We retain such information for as long as
          needed to provide you with the inquiry requested or as required under
          applicable law. We may use third-party services (such
          as ZenDesk or Upclick) to help us provide this service. Hence, your
          data will be shared with them only for processing, tracking, and
          addressing your request.
        </p>

        <h4>06. Business customer information.</h4>
        <p>
          If you are a business partner, or potential business partner, we may
          store certain contact information and job title information about you
          in our CRM systems. This may include full name, work title, work email
          address, and the name of the company in which or for which you work,
          website URL, phone number, and country.
        </p>
        <p>
          <strong>Purpose:</strong> We retain and process this information for
          CRM purposes and business needs, based on your consent and our
          legitimate interest, that may have been provided to a third party that
          introduced your details to us. If we entered into a contract, then
          this will be the lawful basis.
        </p>
        <p>
          <strong>Retention:</strong> We retain this information for as long as
          it is relevant for our business purposes, and, if we entered into a
          contract, then for as long as such contract is valid, relevant, and/or
          for as long as required by applicable local legislation.
        </p>

        <h4>07. Files and/or media uploading.</h4>
        <p>
          Certain Products (such as inPixio, for example) allow you to upload
          documents, files, or media ("your Content"). In the event that your
          Content includes any Personal Information, this information will be
          subject to your use and responsibility. We do not retain your Content,
          rather than any metadata required to operate, process, and support
          your use of the Product. As a rule of thumb, your Content is retained
          locally on your local storage.
        </p>
        <p>
          Upon your choice and request, we may offer you a backup option or
          cloud storage. In this case, it will be encrypted, and we will have no
          access to your Content. In any case, we implemented technical and
          organizational measures to ensure, from our side, maximum protection
          for you while you are using our Websites or Services.
        </p>
        <p>
          <strong>Purpose and Retention:</strong> Such type of data will be
          processed only to the extent necessary and for the time scope required
          to provide you with the Services, operate them, and secure them.
        </p>
      </div>

      {/* ---------------------- */}

      <div>
        <h2>08. Browser and mobile permissions.</h2>
        <p>
          When you choose to install the Product on your mobile device or
          browser, we will ask for your approval (consent) of the permissions
          required for the Product you choose to install. For extended
          functionalities that we may offer from time to time, the operating
          system of your mobile, and its respective application store, will ask
          you from time to time to approve special permission (opt-in consent),
          such as access to your media files.
        </p>

        <h2>09. Job recruitment.</h2>
        <p>
          If you choose to apply for a job in one of our companies, you will be
          asked to submit a form and upload your CV file. In such case, your
          application information will be processed by ADP, LLC, our third-party
          service provider, which provides recruiting services on our behalf.
          For additional information, please see ADP's privacy policy.
        </p>
        <p>
          <strong>Retention:</strong> We retain this information for as long as
          your application for a position is under review and/or for as long as
          required by applicable local legislation.
        </p>

        <h2>Information Automatically Processed</h2>
        <p>
          This category refers to means we use to improve our Customers’ and
          Visitor’s experience and our process performance for our Services.
          When you access one of the Websites, download, install, or otherwise
          use a Product or otherwise use the Services, certain information about
          your actions in connection with the Services, your device, and website
          visitors may be processed in order to provide the Services, maintain
          them, understand User’s usage, improving them with further versions
          and features, supporting them and protecting them. This category
          includes one or more of the following:
        </p>

        <h3>01. Online Identifiers</h3>
        <p>
          When you access our websites or use the Products or Services, we may
          process one or more of the following online identifiers: the IP
          address that your internet provider assigned to your device and access
          to the web, cookies information as specified in our Cookie Policy.
          Like any other digital service, such information is available via your
          local storage and processed through cookies, pixel tags, and log
          files.
        </p>
        <p>
          We use this information to learn about the usage of the Websites, and
          the features we offer, and to extract analytics and statistics over
          time. In certain cases, and when a Visitor becomes a User, we may
          generate a dedicated random User ID to help us avoid providing
          redundant Services and offers.
        </p>
        <p>
          This processing of local storage data and online identifiers is
          subject to your consent which is obtained through the cookie consent
          notification displayed on our websites, in which you can accept,
          decline, or set your preferences (with the exception of strictly
          necessary cookies). We provide full disclosure in our cookie policy
          and information about the type of cookies used, and how and why we use
          them. Please note that necessary cookies will be used as part of our
          legitimate interest and for purposes of Websites’ functionality.
        </p>
        <p>
          <strong>Purposes:</strong> The use of Online Identifiers serves one or
          more of the following purposes: (i) As part of our legitimate
          interest, we use certain online information for analytics and
          statistical purposes when we want to learn how Visitors and Users use
          our Websites and Services, and when we want our Services to be
          responsive to Users’ and Visitors’ actions. (ii) We also use IP
          addresses many times as part of our legitimate interest to monitor,
          detect and prevent fraud or any automated non-human actions across our
          Websites and Services. The IP address also serves our legitimate
          interest to learn the country from which you access the Services and
          make sure we serve the Products and Services in the right language and
          in compliance with the jurisdiction in which we offer our Products and
          Services. (iii) In certain cases, and upon your consent, third-party
          cookies and tags will be used for advertising and marketing purposes
          of our Products and Services, including by using retargeting. See
          further explanation under ‘Third Party Services’.
        </p>
        <p>
          <strong>Retention:</strong> The retention periods of each of the
          information mentioned in this section are different. You may visit the
          Cookie Policy available per website to learn about each cookie’s or
          web technology’s retention and expiry term. Otherwise, we retain
          online information for as long as necessary to achieve the purpose for
          which it is processed, to begin with, or, until we make it
          Non-Personal Information, in which case, we reserve the right to
          retain the information without any time limitation, based on our
          discretion and commercial necessities.
        </p>

        <h3>02. Device Information</h3>
        <p>
          We may collect certain information about the device from which you
          access the Websites, use Products, and Services, such as user-agent
          (that includes the browser type and version, IP address, operating
          system type, and version), device type (mobile/desktop), type and
          version of your operating system, your MAC address, the language you
          use on your device, and, when you choose to purchase a license to more
          than one device, we associate your license key and user ID with your
          email address and device information.
        </p>
        <p>
          <strong>Purposes:</strong> Based on your consent, and in certain
          cases, our legitimate interest, we may process device information for
          one or more of the following purposes: (i) Product’s compatibility
          purposes, to the right device type, the relevant operating system’s
          version, and features. (ii) Offering additional Services or features
          specifically designed for your device type, for instance, mobile vs.
          desktop. (iii) To associate a license key issued to you with the
          device on which you install the Product. (iv) The Products’ core
          functionality is to help you manage your device’s space and optimize
          your ability to use it. In that case, as part of the Product’s
          functionality and based on our legitimate interest we will access
          certain device information that is required to provide, maintain, and
          support such functionality.
        </p>
        <p>
          <strong>Retention:</strong> We retain device information for as long
          as necessary to provide the Product’s functionality, to maintain a
          record of your license key and usage of the product, and to associate
          license terms and renewals.
        </p>

        <h3>03. Online Activity</h3>
        <p>
          We also process certain online activity information from Visitors, for
          example, if you click an advertisement of our Product, we will get the
          source URL in which you click the advertisement, or, by using
          third-party services (such as retargeting), we may offer to you one or
          more of our Products when you visit other websites. Under this
          category, we may also capture your clicks on buttons in log files
          across our Websites or online Services, if provided, as described
          under the category of ‘Online Identifiers’.
        </p>
        <p>
          <strong>Purpose:</strong> We use this information for the following
          purposes: (i) Based on your consent for advertising, marketing, and
          retargeting of our Products and Services. (ii) Based on our legitimate
          interest – to make the Website, Products, and Services responsive and
          functional, to measure and calculate our payments to advertisers and
          to monitor and prevent ad fraud concerning our Products and Services.
        </p>
      </div>

      {/* ------------------ */}

      <div className="content-section">
        <h2>04. Installation Reports</h2>
        <p>
          When you install one or more of our Products, our server may receive
          some information about your systems such as the type of your Operating
          System, version, language, timestamp of installation and the IP
          address that your internet service provider allocates to your
          computer, for purposes of ensuring that the device and installation
          are real and not fraudulent and to ensure that the device is one that
          was not already installed the same product before (anti-fraud
          purposes). We need this data so we can serve the installed file,
          operate it and ensure compatibility.
        </p>
        <h3>Purpose:</h3>
        <p>
          This information is collected for purposes of enabling the
          installation of the Product, recording it in our systems, being able
          to serve you with the Services via our servers when you use the
          Product, and tracking and making sure that the device and installation
          are real and not fraudulent. We also need this data so we can serve
          the installed file, operate it and ensure compatibility.
        </p>
        <h3>Retention:</h3>
        <p>
          We retain this information for as long as the Product is installed on
          your device, and sometimes a bit longer afterward if we believe in our
          discretion that your device and any associated installation are
          associated with fraudulent installations or any other malicious usage
          of our Services.
        </p>

        <h2>Information Automatically Processed</h2>
        <p>
          This category refers to means we use to improve our Customers’ and
          Visitor’s experience and our process performance for our Services.
        </p>
        <p>
          When you access one of the Websites, download, install or otherwise
          use a Product or otherwise use the Services, certain information about
          your actions in connection with the Services, your device, and website
          visitors may be processed in order to provide the Services, maintain
          them, understand User’s usage, improving them with further versions
          and features, supporting them and protecting them. This category
          includes one or more of the following:
        </p>

        <h3>01. Online Identifiers</h3>
        <p>
          When you access our websites or use the Products or Services, we may
          process one or more of the following online identifiers: the IP
          address that your internet provider assigned to your device and access
          to the web, cookies information as specified in our Cookie Policy.
          Like any other digital service, such information is available via your
          local storage and processed through cookies, pixel tags, and log
          files.
        </p>
        <p>
          We use this information to learn about the usage of the Websites, and
          the features we offer, and to extract analytics and statistics over
          time. In certain cases, and when a Visitor becomes a User, we may
          generate a dedicated random User ID to help us avoid providing
          redundant Services and offers.
        </p>
        <p>
          This processing of local storage data and online identifiers is
          subject to your consent which is obtained through the cookie consent
          notification displayed on our websites, in which you can accept,
          decline, or set your preferences (with the exception of strictly
          necessary cookies). We provide full disclosure in our cookie policy
          and information about the type of cookies used, and how and why we use
          them. Please note that necessary cookies will be used as part of our
          legitimate interest and for purposes of Websites’ functionality.
        </p>

        <h3>Purposes:</h3>
        <p>
          The use of Online Identifiers serves one or more of the following
          purposes:
          <ol>
            <li>
              As part of our legitimate interest, we use certain online
              information for analytics and statistical purposes when we want to
              learn how Visitors and Users use our Websites and Services, and
              when we want our Services to be responsive to Users’ and Visitors’
              actions.
            </li>
            <li>
              We also use IP addresses many times as part of our legitimate
              interest to monitor, detect and prevent fraud or any automated
              non-human actions across our Websites and Services. The IP address
              also serves our legitimate interest to learn the country from
              which you access the Services and make sure we serve the Products
              and Services in the right language and in compliance with the
              jurisdiction in which we offer our Products and Services.
            </li>
            <li>
              In certain cases, and upon your consent, third-party cookies and
              tags will be used for advertising and marketing purposes of our
              Products and Services, including by using retargeting. See further
              explanation under ‘Third Party Services’.
            </li>
          </ol>
        </p>

        <h3>Retention:</h3>
        <p>
          The retention periods of each of the information mentioned in this
          section are different. You may visit the Cookie Policy available per
          website to learn about each cookie’s or web technology’s retention and
          expiry term. Otherwise, we retain online information for as long as
          necessary to achieve the purpose for which it is processed, to begin
          with, or, until we make it Non-Personal Information, in which case, we
          reserve the right to retain the information without any time
          limitation, based on our discretion and commercial necessities.
        </p>

        <h3>02. Device Information</h3>
        <p>
          We may collect certain information about the device from which you
          access the Websites, use Products, and Services, such as user-agent
          (that includes the browser type and version, IP address, operating
          system type, and version), device type (mobile/desktop), type and
          version of your operating system, your MAC address, the language you
          use on your device, and, when you choose to purchase a license to more
          than one device, we associate your license key and user ID with your
          email address and device information.
        </p>
        <h3>Purposes:</h3>
        <p>
          Based on your consent, and in certain cases, our legitimate interest,
          we may process device information for one or more of the following
          purposes:
          <ol>
            <li>
              Product’s compatibility purposes, to the right device type, the
              relevant operating system’s version, and features.
            </li>
            <li>
              Offering additional Services or features specifically designed for
              your device type, for instance, mobile vs. desktop.
            </li>
            <li>
              To associate a license key issued to you with the device on which
              you install the Product.
            </li>
            <li>
              The Products’ core functionality is to help you manage your
              device’s space and optimize your ability to use it. In that case,
              as part of the Product’s functionality and based on our legitimate
              interest we will access certain device information that is
              required to provide, maintain, and support such functionality.
            </li>
          </ol>
        </p>
        <h3>Retention:</h3>
        <p>
          We retain device information for as long as necessary to provide the
          Product’s functionality, to maintain a record of your license key and
          usage of the product, and to associate license terms and renewals.
        </p>

        <h3>03. Online Activity</h3>
        <p>
          We also process certain online activity information from Visitors, for
          example, if you click an advertisement of our Product, we will get the
          source URL in which you click the advertisement, or, by using
          third-party services (such as retargeting), we may offer to you one or
          more of our Products when you visit other websites. Under this
          category, we may also capture your clicks on buttons in log files
          across our Websites or online Services, if provided, as described
          under the category of ‘Online Identifiers’.
        </p>
        <h3>Purpose:</h3>
        <p>
          We use this information for the following purposes:
          <ol>
            <li>
              Based on your consent for advertising, marketing, and retargeting
              of our Products and Services.
            </li>
            <li>
              Based on our legitimate interest – to make the Website, Products,
              and Services responsive and functional, to measure and calculate
              our payments to advertisers and to monitor and prevent ad fraud
              concerning our Products and Services.
            </li>
          </ol>
        </p>
      </div>

      {/* ------------ */}

      <div className="privacy-policy-section">
        <h2 className="section-title">04. Installation Reports</h2>
        <p>
          When you install one or more of our Products, our server may receive
          some information about your systems such as the type of your Operating
          System, version, language, timestamp of installation and the IP
          address that your internet service provider allocates to your
          computer, for purposes of ensuring that the device and installation
          are real and not fraudulent and to ensure that the device is one that
          was not already installed the same product before (anti-fraud
          purposes). We need this data so we can serve the installed file,
          operate it and ensure compatibility.
        </p>
        <p>
          <strong>Purpose:</strong> This information is collected for purposes
          of enabling the installation of the Product, recording it in our
          systems, being able to serve you with the Services via our servers
          when you use the Product, and tracking and making sure that the device
          and installation are real and not fraudulent. We also need this data
          so we can serve the installed file, operate it and ensure
          compatibility.
        </p>
        <p>
          <strong>Retention:</strong> We retain this information for as long as
          the Product is installed on your device, and sometimes a bit longer
          afterward if we believe in our discretion that your device and any
          associated installation are associated with fraudulent installations
          or any other malicious usage of our Services.
        </p>

        <h2 className="section-title">05. Product Activity</h2>
        <p>
          We automatically record and process activities related to the
          Product’s usage and functionality to be responsive to them or allow
          you to revert certain actions you took.
        </p>

        <h2 className="section-title">
          06. Media Buying and Marketing Channels
        </h2>
        <p>
          We use digital media channels to market and advertise our Products and
          Services. When you land on our website as a result of clicking an ad,
          we will receive information about the source media from which you
          arrived at our Services. This information includes a referral URL (the
          URL from which you arrived at our Site) and your IP address and will
          be collected via tags and cookies. The purpose of processing this data
          is to know the marketing channel from which you arrived, measure and
          calculate purposes for the advertising, measure the effectiveness of a
          marketing channel and filter fraudulent actions associated with such
          marketing and advertising channels.
        </p>

        <h2 className="section-title">Information About You</h2>
        <p>
          This category refers to data we may receive from third parties
          concerning the Services we provide to you.
        </p>
        <p>
          This category includes information you provided to third party and we
          may get access to it. this includes the following:
        </p>

        <h3 className="subsection-title">01. Billing Information</h3>
        <p>
          When you purchase a Product your payment and billing information is
          collected and processed by our payment processor vendor. In this case,
          we will get access to the order and billing information, however, we
          will not have any access to your credit card details.
        </p>

        <h3 className="subsection-title">
          02. Authentication and Social Media
        </h3>
        <p>
          When you open an account and/or sign up for the Services, you may have
          the option to use social or email authentication. In this case, again,
          we will have access to metadata (such as tokens), and upon your
          consent, your social media’s public profile.
        </p>

        <h3 className="subsection-title">03. Marketing</h3>
        <p>
          When we buy media and market our Products and Services, we may get
          certain online information about visitors from third-party media
          owners, such as Facebook or Google. This information does not
          personally identify you and is used to learn how successful (or not)
          our campaigns are.
        </p>

        <h2 className="section-title">Cookies and Web Technologies</h2>
        <p>
          A cookie is a small text file that is placed on the browser of the
          hard drive of your computer (or similar device) by websites you visit.
          Cookies are typically used in order to make websites function, or
          function more efficiently, as well as to provide information to the
          operator of the particular website. Cookies make your use of the Site
          and Services easier to use and improve their functionality. They are
          used to make the login process easier for our users, to allow the
          website to track which products you have added to your cart and your
          preferred country, language, and currency, and to track the website’s
          Visitor across the Site.
        </p>
        <p>
          Some of the Services, features, and tools that are integrated into our
          Site and Services may use their own cookies to be able to function
          properly. To know more about our cookies and our 3rd party cookies,
          please refer to the cookie policy available from the website you
          browse. Please be aware that you may set your browser to refuse or
          block any cookies from us or our 3rd party partners. In this case,
          however, some of the Services may not properly function.
        </p>

        <h2 className="section-title">
          Purpose and How We Use Personal Information
        </h2>
        <p>
          We use your Personal Information for the purposes we described next to
          each data type above, and for the following purposes:
        </p>
        <ul>
          <li>
            Providing our Services, supporting them, improving them, and
            securing them so as to better market and advertise them.
          </li>
          <li>
            We use certain information to prevent fraud and fake installations.
          </li>
          <li>Open an account for you, manage it and support it.</li>
        </ul>

        <h2 className="section-title">
          Lawful Basis for Processing Personal Information
        </h2>
        <p>
          In addition to the lawful basis mentioned next to each data category:
        </p>
        <p>
          If you are a Customer, our lawful basis for processing your Personal
          Information is a contract or preparation for contractual engagement.
          Otherwise, we will process Personal Information based on your consent,
          if required by Applicable Laws, or legitimate interest when processed
          for such an interest. Otherwise, the lawful basis for data processing
          will depend on the Personal Information concerned and the specific
          context in which we collect it: (a) we normally process Personal
          Information where we have your consent to do so, for example, when you
          submit a form to us or open an account with us, or confirm our cookies
          usage; or, in certain cases (b) where the processing is in our
          legitimate interests and not overridden by your data protection
          interests or fundamental rights and freedoms, such as when we use the
          data in order to provide the core Services, or when used for
          anti-fraud purposes.
        </p>
        <p>
          In some cases, we may also have a legal obligation to collect Personal
          Information from you or may otherwise need the Personal Information to
          protect your vital interests or those of another person. If we ask you
          to provide Personal Information to comply with a legal requirement or
          to perform a contract with you, we will make this clear at the
          relevant time and advise you whether the provision of your Personal
          Information is mandatory or not.
        </p>
        <p>
          If you have questions about or need further information concerning the
          legal basis on which we collect and use your Personal Data, including
          if you would like to better understand how our legitimate interests in
          processing your data are balanced against your data protection rights
          and freedoms, please contact us.
        </p>
      </div>

      {/* --------------- */}

      <h2 className="mb-4">How We Share Personal Information</h2>
      <p className="mb-3">
        In order to provide, operate, maintain, serve, and improve our Services,
        so as to be able to offer additional products and features further, we
        use third-party services. Such Services include payment processors,
        feedback features, support and ticketing systems, operational tools,
        analytics, statistical tools, and so forth.
      </p>
      <p className="mb-3">
        This means that we may share Personal Information of Users with the
        following categories of third parties for the respective purposes:
      </p>
      <ol className="list-decimal list-inside">
        <li>
          <strong>Hosting Services and Storage Vendors:</strong> We use
          third-party hosting and storage services (including cloud storage) to
          host our Websites, and respective data and retain your Information.
          Such Processors may be based (and their servers may be located)
          anywhere in the world. We require each such Processor to maintain
          strict privacy protection and data security policies and ensure their
          compliance with applicable data protection laws. However, their
          practices and activities are fully governed by their own privacy
          policies.
        </li>
        <li>
          <strong>
            Analytics, Business Intelligence, and Statistics Vendors:
          </strong>{" "}
          If you are a User and visiting our Sites, we may use analytics
          services to help us understand how Users interact with our Sites and
          Services.
        </li>
        <li>
          <strong>Support and Customer Services:</strong> We may use, from time
          to time, support and help desk services to provide better and faster
          support, whether online or, when you contact customer support.
        </li>
        <li>
          <strong>Authentication and Authorization Services:</strong> In cases
          where you open an account with us, we may integrate over time certain
          authentication services.
        </li>
        <li>
          <strong>Payment Processors:</strong> When you choose to purchase one
          or more of our products, we may use payment processing services
          carrying strict security standards.
        </li>
        <li>
          <strong>Customer Relations Management and Mailing Lists:</strong> If
          you are a Customer or a User, we may use services to manage and secure
          your Personal Information, or, manage subscriptions and notifications
          lists.
        </li>
        <li>
          <strong>Technology Partners:</strong> Some of our products and
          Services may include third-party technologies, widgets, or features in
          order to enhance a product’s functionality and expand it to include
          more features for you. For example, WordPress.
        </li>
        <li>
          <strong>Advertising and Retargeting Services:</strong> Some of our
          third-party services may collect non-identifiable information about
          your interaction with our Websites. Such information may be used by
          such third parties for serving and displaying ads and offers of the
          Site and/or content and services offered via the Site (“Ads”) when you
          browse other websites across the web (“Retargeting”). You will have
          the choice to opt-out at any time of such type of advertising,
          directly from the Ad when you encounter it, from the ad setting of the
          providing third-party services, or by following our opt-out
          instructions, which are available in our cookie policy.
        </li>
        <li>
          <strong>Business Partners:</strong> who combine their technological
          features with us may have access to certain Personal Information about
          your usage, as collected from you. In these cases, you will always be
          able to check their privacy policies before using the combined
          services.
        </li>
        <li>
          <strong>Our Affiliated Companies:</strong> We may disclose Personal
          Information to any current or future affiliated company, parent
          companies, or subsidiaries to process for the purposes described in
          this Policy. Affiliated companies are companies controlling,
          controlled by, or under common control with us, including, for
          example, NETGET ROUTER North America. However, data will not be shared
          with third parties for marketing or promotional purposes.
        </li>
        <li>
          <strong>Legal Proceedings or Law Requirements:</strong> We may share
          your Personal Information with any third-party if we believe that
          disclosure of such information is helpful or reasonably necessary to:
          (a) comply with any Applicable Law, regulation, legal process, or
          governmental request; (b) enforce our license agreement, terms of
          service or this Policy, including investigations of potential
          violations thereof; (c) detect, prevent, or otherwise address fraud or
          security issues; and/or (d) protect against harm to the rights,
          property or safety of our companies, our affiliated entities, our
          users, yourself, and/or the public.
        </li>
        <li>
          <strong>Merger, Sale, or Bankruptcy:</strong> In the event that we or
          one of our affiliated entities is acquired by, or merged with, a
          third-party entity or otherwise sells all or part of our/its assets,
          we may (and hereby reserve the right to) transfer or assign the
          Personal Information and other information we have collected or
          received. In such a case, we will require the acquiring entity to post
          its data practices and provide you with any of your rights as per the
          jurisdiction of your residency.
        </li>
        <li>
          <strong>Special Cases:</strong> We may share information upon your
          consent or in fraud prevention or investigation cases. We may store
          Non-Personal and Personal Information on our servers or our cloud
          servers, use or share Non-Personal Information in any of the above
          circumstances, and provide and improve our Service as detailed above.
          Furthermore, we reserve the right to use, disclose or transfer (for
          business purposes or otherwise) aggregated and processed data to third
          parties, including, inter alia, affiliates, for various purposes,
          including commercial use. This information may be collected,
          processed, and analyzed by us and transferred in a combined,
          collectively, and aggregated manner (i.e., your information is
          immediately aggregated with other users) to third parties.
        </li>
      </ol>
      {/* ------------ */}
      <h2>International transfers of data</h2>
      <p>
        We may transfer your Information (including Personal Information)
        outside the country in which it is collected or in which you reside
        (e.g., to the United Kingdom or the United States), including to a
        country that may not offer the same level of protection for Personal
        Information as of the country in which you reside. However, in which
        case we make sure to place all contractual safeguards (such as
        verification of an adequate level of protection measures, security
        certificates and other measures that help us assess the security level
        of a third-party service).
      </p>
      <p>
        By providing Personal Information and using the Services, you expressly
        consent to the use of such information in accordance with this Privacy
        Policy and to the transfer of your Information to another country
        (including, the UK or the US).
      </p>

      <h3>Rights of Data Subjects</h3>
      <h4>01. Privacy rights available to all Users</h4>
      <p>
        Depending on your country of residency and on the type of your use of
        our Products and Services, certain rights concerning your Personal
        Information may be available to you. If you are a Visitor of our Sites
        or a User of the Services, the following rights apply to you:
      </p>
      <ul>
        <li>
          <strong>The right to know:</strong> you have the right to know about
          the Personal Information that is collected and processed about you,
          including the categories of third parties with which we may share your
          information. To accommodate this right, we made this Policy as
          detailed and transparent as possible, and we provide additional
          disclosures and notifications throughout the Services, in proximity to
          the Personal Information collection point.
        </li>
        <li>
          <strong>The right to access:</strong> the Personal Information held
          about you.
        </li>
        <li>
          <strong>The right to rectification:</strong> you have the right to ask
          us to correct the information we hold about you. When you manage an
          Account with us, you can access this option at any time directly from
          your account.
        </li>
        <li>
          <strong>The right of erasure:</strong> you may ask us to delete
          certain Personal Information about you, or to stop using it. We can
          only do this when possible and as allowed by applicable laws.
          Sometimes we need your information to complete a transaction based on
          an action you made, comply with the law, or provide you with Products
          and Services.
        </li>
        <li>
          <strong>Portability right:</strong> you may contact us to request an
          export of your Personal Information in a reusable format, or to
          directly transfer such data to another vendor offering related
          services.
        </li>
        <li>
          <strong>The right to withdraw consent previously given:</strong> where
          you have given us your consent to use your Personal Information for a
          specific purpose, and in the absence of our legitimate interest or
          requirements by applicable law, you may decide to withdraw your
          consent and we will then stop using your information for that purpose.
        </li>
      </ul>

      <h4>02. Privacy rights for specific jurisdictions</h4>
      <p>
        In addition to the general rights outlined above, you may additionally
        refer to the region-specific rights related to your Personal
        Information.
      </p>

      <h4>03. Exercising your privacy rights</h4>
      <p>
        Please note, requests to exercise your rights must be verifiable to be
        processed.
      </p>
      <ul>
        <li>
          <strong>Directly from your account:</strong> Most rights are
          exercisable and available directly from the account you have with us
          (such as edit, portability, and deletion request), or from their
          respective services and communications (such as unsubscription/opt-out
          etc.).
        </li>
        <li>
          <strong>Form submission:</strong> you can complete this form and
          either submit it digitally or send it by email to
          <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>
        </li>
        <li>
          <strong>Email:</strong> reaching out to us at:
          <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>
        </li>
        <li>
          <strong>Post:</strong> by post to: NETGET ROUTER Software SAS,
          Immeubles Adamas, 2 rue Berthelot, CS 90142, P2414 COURBEVOIE CEDEX,
          France.
        </li>
      </ul>
      <p>
        Make sure to include sufficient information about the request, the
        related Service, and the Data Subject to allow us to verify you and your
        request and we will process your verifiable request within the timeframe
        indicated in the applicable regulation. Please note that when handling
        these requests, we may ask for additional information from you.
      </p>
      <p>
        Please note that even if you unsubscribe from our service, we may still
        send you transactional emails related to purchases or product downloads,
        such as order confirmations, product notices, expiry notifications,
        bug-fix notifications, security alerts, compatibility notifications, or
        enhancements.
      </p>
      <p>
        Please note that response times for exercising your rights may vary
        based on residency. Please refer to the Special Jurisdiction chapter for
        more information on response times in your region.
      </p>

      <h3>Information security</h3>
      <p>
        We implement organizational and technical measures to protect Customers'
        and Users' privacy and to protect their Personal Information, including,
        by using third-party services specializing in data security. We
        periodically review our information and data collection practices to
        ensure constant monitoring, we use third-party processing and serving
        services that implement various information security standards and
        certifications and we monitor and filter access to data when it comes to
        personnel in our organization. Such systems and procedures reduce the
        risk of security breaches, but they do not provide absolute security, as
        there is no such thing when it comes to the worldwide network.
        Therefore, we cannot guarantee that the Site and our servers are immune
        to unauthorized access to the information stored therein and to other
        information security risks. If you have any questions about the security
        of our Websites or Services, you can contact us at
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>
      </p>
      <p>Highlights of our general Security Policy can be found here.</p>

      <h3>Children</h3>
      <p>
        The Services are not designed to attract children under the age of 16.
        Accordingly, we do not intend to collect Personal Information from
        anyone we know to be under 16. If we learn that we have collected
        Personal Information from a child under 16, we will use commercially
        reasonable efforts to delete that information as quickly as possible. If
        you believe that we might have any such information, please contact us
        at{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>
      </p>

      <h3>Contact information</h3>
      <p>
        If you have any inquiries regarding this Privacy Policy, don’t hesitate
        to contact us at:{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>.
      </p>

      <h3>Special Jurisdictions: The EU, EEA and the UK</h3>
      <p>
        This statement applies to Data Subjects residing in the EU, EEA or the
        UK, and supplements the terms provided in the privacy statement. Our
        general privacy statement was designed with the EU GDPR transparency
        requirements in mind. The following data subject rights will be
        available in addition to those described under the general statement:
      </p>
      <ul>
        <li>
          <strong>Right to the restriction of processing:</strong> you may ask
          us to temporarily cease the processing of your Personal Information,
          or, only for a specific purpose or function. Please note that this too
          might cause us to not be able to provide you with the Products and
          Services or part thereof.
        </li>
        <li>
          <strong>Objection to processing:</strong> You may request us to stop
          processing your Personal Information. Similar exceptions as mentioned
          above will apply to such a case as well.
        </li>
        <li>
          <strong>Right to lodge a complaint:</strong> if you live in the EU,
          EEA or UK, you may lodge a complaint with the authority responsible
          for protecting Personal Information in your country of residence, if
          you believe that your rights have not been respected by us. In such a
          case, you are welcome to reach out to us first so we are able to help
          you with such a matter at:{" "}
          <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>
          .
        </li>
        <li>
          For any data protection-related matter, you may contact our group DPO
          at{" "}
          <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>
          .
        </li>
      </ul>

      <h3>Special jurisdictions: California, USA</h3>
      <p>Last Updated: January 11, 2023</p>
      <p>In compliance with CCPA, CPRA, CalOPPA</p>
      <p>
        This statement applies to Data Subjects residing in California, USA, and
        supplements the terms provided in the general privacy statement.
      </p>
      {/* -------- */}

      <h4 className="fw-bold mb-3">
        The following rights related to your Personal Information are available
        to you in addition to those described under the general statement:
      </h4>
      <p className="mb-3">
        California Privacy Rights: Under California law, you may have the
        following rights:
      </p>

      <ul className="list-group mb-3">
        <li className="list-group-item">
          <strong>The right to know:</strong> You have the right to request
          certain information related to the collection, use, disclosure, and
          sale of your Personal Information.
        </li>
        <li className="list-group-item">
          <strong>The right to access:</strong> You may request access to the
          specific pieces of Personal Information collected about you in the
          past 12 months.
        </li>
        <li className="list-group-item">
          <strong>The right to deletion:</strong> You have the right to request
          the deletion of your Personal Information.
        </li>
        <li className="list-group-item">
          <strong>The right to opt-out:</strong> You may opt out of the sale of
          your Personal Information. We do not sell your Personal Information.
        </li>
        <li className="list-group-item">
          <strong>Shine the Light:</strong> You may request a list of the third
          parties with whom we have shared your Personal Information for direct
          marketing purposes. We do not share your Personal Information for
          direct marketing purposes.
        </li>
        <li className="list-group-item">
          <strong>Non-Discrimination:</strong> We will not discriminate against
          you for exercising your privacy rights under California law.
        </li>
      </ul>

      <p className="mb-4">
        To exercise your California privacy rights, please contact us at:{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>.
      </p>
      <p className="mb-5">
        For more details about the elements in each category, please refer back
        to our Privacy Policy.
      </p>

      <h5 className="fw-bold mb-3">2. How we may share personal information</h5>
      <p className="mb-3">
        As a rule, we do not sell your personal information or share it for
        monetary gain. We do use advertising services of vendors such as Google,
        Facebook, Bing, and LinkedIn, which under California law may be
        considered data selling. If you wish to opt-out of this type of data
        selling (i.e., sharing of IP address and cookies information for
        targeted advertising), you can use the opt-out option available from the
        footer of the page labeled as "Do not sell my data."
      </p>
      <p className="mb-4">
        We may share your personal information with a third party for a business
        purpose, including our affiliated companies. When we do so, we enter
        into a contract that describes the purpose and requires the recipient to
        both keep that personal information confidential and not use it for any
        purpose except performing the contract.
      </p>

      <p className="fw-bold mb-3">
        In the preceding twelve (12) months, we have shared the following
        categories of personal information for business purposes:
      </p>

      <ul className="list-group">
        <li className="list-group-item">
          <strong>All categories:</strong> Our hosting, processing,
          infrastructure, and security service providers in order to provide,
          facilitate, process, and secure the Applications, the data, and the
          services.
        </li>
        <li className="list-group-item">
          <strong>Internet and network data:</strong> Service providers, web
          platforms, analytics services, and online marketing platforms.
        </li>
        <li className="list-group-item">
          <strong>Support and customer services:</strong> Support and help desk
          services.
        </li>
        <li className="list-group-item">
          <strong>Customer relations management and mailing list:</strong>{" "}
          Manage and secure your personal information, or manage subscriptions
          and notifications lists.
        </li>
        <li className="list-group-item">
          <strong>Business relations:</strong> CRM and mailing list platforms.
        </li>
        <li className="list-group-item">
          <strong>Contact information:</strong> Customer support services.
        </li>
        <li className="list-group-item">
          <strong>Billing information:</strong> Payment processing.
        </li>
        <li className="list-group-item">
          <strong>Advertising and retargeting services:</strong> Displaying and
          serving ads and offers.
        </li>
        <li className="list-group-item">
          <strong>Affiliated companies:</strong> We may need to disclose your
          data with our affiliated companies, parent companies, or subsidiaries
          to provide our services.
        </li>
        <li className="list-group-item">
          <strong>
            Technology partners that are integrated into products or websites:
          </strong>{" "}
          Widgets, or features in order to enhance a product’s functionality.
        </li>
        <li className="list-group-item">
          <strong>Cookies and certain online identifiers:</strong> Marketing and
          advertising of the Services, analytics, Website, and Services
          functionality.
        </li>
        <li className="list-group-item">
          <strong>Legal proceedings or law requirements:</strong> Complying with
          applicable laws, regulations, legal processes, or governmental
          requests.
        </li>
        <li className="list-group-item">
          <strong>Merger, sale, or bankruptcy:</strong> Due diligence according
          to structural changes.
        </li>
        <li className="list-group-item">
          <strong>Special cases:</strong> Such as fraud prevention or
          investigation.
        </li>
      </ul>

      {/* ------------------- */}

      <h4 className="fw-bold mb-3">3. Consumer privacy rights</h4>
      <p className="mb-3">
        Under the CPRA, you may exercise the following rights:
      </p>

      <h5 className="fw-bold mb-3">Access and data portability rights</h5>
      <p className="mb-3">
        You may request us to access certain information we may hold about you
        in the past 12 months. You may also ask us to provide it to you in a
        human-readable fashion (subject to exemptions). If you have an account
        with us, you can exercise your right directly via your account or make a
        request to us via the form available in the portal or via email to{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>.
        Upon receipt of your verifiable request, and provided no exceptions
        apply, we will process the requested information. The right to access
        includes the following types of information (preceding 12 months):
      </p>

      <ul className="list-group mb-4">
        <li className="list-group-item">
          The categories of personal information we collected about you.
        </li>
        <li className="list-group-item">
          The categories of sources for the personal information we collected
          about you.
        </li>
        <li className="list-group-item">
          Our business or other purposes for collecting that personal
          information.
        </li>
        <li className="list-group-item">
          The categories of third parties with whom we share that personal
          information.
        </li>
        <li className="list-group-item">
          The specific pieces of personal information we collected about you
          (also called a data portability request).
        </li>
        <li className="list-group-item">
          If we sold or shared your personal information for a business purpose.
        </li>
      </ul>

      <p className="mb-3">
        You may only make such a request for access or data portability twice
        within a 12-month period.
      </p>

      <h5 className="fw-bold mb-3">Delete personal data</h5>
      <p className="mb-3">
        You have the right to request the deletion of personal information
        retained about you, subject to certain exceptions (see below). If you
        have an account with us, you can exercise your right directly via your
        account or make a request to us as described below. When applicable,
        once we receive and confirm your verifiable consumer request, we will
        delete (and direct our service providers to delete) your personal
        information from our records, unless an exception applies.
      </p>

      <p className="mb-3">
        We may deny your deletion request if retaining the information is
        necessary for us or our service providers to:
      </p>

      <ul className="list-group mb-4">
        <li className="list-group-item">
          Complete the transaction for which we collected the personal
          information, provide a good or service that you requested, take
          actions reasonably anticipated within the context of our ongoing
          business relationship with you, or otherwise perform our contract with
          a customer/you.
        </li>
        <li className="list-group-item">
          Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity, or prosecute those responsible for
          such activities.
        </li>
        <li className="list-group-item">
          Debug products to identify and repair errors that impair existing
          intended functionality.
        </li>
        <li className="list-group-item">
          Exercise free speech, ensure the right of another consumer to exercise
          their free speech rights, or exercise another right provided for by
          law.
        </li>
        <li className="list-group-item">
          Comply with the California Electronic Communications Privacy Act
          (CalOPPA) or any other applicable law.
        </li>
        <li className="list-group-item">
          Engage in public or peer-reviewed scientific, historical, or
          statistical research in the public interest that adheres to all other
          applicable ethics and privacy laws, when the information’s deletion
          may likely render impossible or seriously impair the research’s
          achievement if you previously provided informed consent.
        </li>
        <li className="list-group-item">
          Enable solely internal uses that are reasonably aligned with consumer
          expectations based on your relationship with us.
        </li>
        <li className="list-group-item">Comply with a legal obligation.</li>
        <li className="list-group-item">
          Make other internal and lawful uses of that information that are
          compatible with the context in which you provided it.
        </li>
      </ul>

      <h5 className="fw-bold mb-3">Rectify personal information</h5>
      <p className="mb-3">
        You have the right to request that we correct any inaccurate personal
        information that we have collected about you, including:
      </p>

      <ul className="list-group mb-4">
        <li className="list-group-item">
          The right to request that we delete any incomplete or inaccurate
          personal information that we have collected about you.
        </li>
        <li className="list-group-item">
          The right to request that we provide a method to directly correct your
          personal information only if you have an online account and the
          information is reasonably available through the account.
        </li>
      </ul>

      <p className="mb-4">
        Note: These rights to rectification only apply to the personal
        information we collected about you directly and do not apply to personal
        information that we received from a third party.
      </p>

      <h5 className="fw-bold mb-3">Opt-out of data selling or sharing</h5>
      <p>
        NETGET ROUTER is not in the business of selling, renting, or disclosing
        personal information to third parties for their direct marketing goals.
        However, you may request that we do not sell or share your data. To make
        such a request, please email us at{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>.
      </p>

      {/* --------------------- */}
      <h4 className="fw-bold mb-3">
        4. How to exercise your consumer privacy rights
      </h4>
      <p className="mb-4">
        To exercise your rights, you may contact us via email at{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>.
        You may also make a request through the online forms available on our
        website. We will require your email address or contact information to
        verify your identity.
      </p>

      <h4 className="fw-bold mb-3">5. Response Timing and Format</h4>
      <p className="mb-4">
        We strive to respond to a verifiable consumer request within 45 days of
        its receipt. If we require more time and meet the extension requirements
        defined by law (up to 90 days), we will inform you of the reason and
        extension period in writing (electronically).
      </p>
      <p className="mb-4">
        If you do not have an account with us, we will deliver our written
        response by email. Any disclosures we provide will only cover the
        12-month period preceding the verifiable consumer request’s receipt. The
        response we provide will also explain the reasons we cannot comply with
        a request if that is the case.
      </p>
      <p className="mb-4">
        For data portability requests, we will select a format to provide your
        personal information that is readily usable and should allow you to
        transmit the information from one entity to another entity without
        hindrance.
      </p>
      <p className="mb-4">
        We do not charge a fee to process or respond to your verifiable consumer
        request unless it is excessive, repetitive, or manifestly unfounded. If
        we determine that the request warrants a fee, we will tell you why we
        made that decision and provide you with a cost estimate before
        completing your request.
      </p>

      <h4 className="fw-bold mb-3">6. Non-Discrimination</h4>
      <p className="mb-4">
        We will not discriminate against you for exercising any of your CPRA
        rights. Unless permitted by the CPRA or any other law, or due to your
        violations, we will not:
      </p>
      <ul className="list-group mb-4">
        <li className="list-group-item">Deny you goods or services.</li>
        <li className="list-group-item">
          Charge you different prices or rates for goods or services, including
          through granting discounts or other benefits, or imposing penalties.
        </li>
        <li className="list-group-item">
          Provide you with a different level or quality of goods or services.
        </li>
        <li className="list-group-item">
          Suggest that you may receive a different price or rate for goods or
          services or a different level or quality of goods or services.
        </li>
      </ul>

      <h4 className="fw-bold mb-3">7. Do not Track Signals (CalOPPA)</h4>
      <p className="mb-4">
        Do Not Track is a privacy preference that can be configured in certain
        web browsers (the “DNT Feature”); the DNT Feature, when enabled on a web
        browser, signals the websites you visit that you do not want certain
        information about your visit collected. Undertone does not currently
        respond or recognize DNT Feature signals.
      </p>

      <h4 className="fw-bold mb-3">8. Changes to this privacy statement</h4>
      <p className="mb-4">
        We reserve the right to amend this privacy notice at our discretion and
        at any time. When we make changes to this privacy notice, we will notify
        you as per the notification mechanism described in our Privacy Policy,
        or, as otherwise required by applicable laws.
      </p>

      <h4 className="fw-bold mb-3">9. Contact info</h4>
      <p className="mb-4">
        If you have any questions or comments about this notice, our privacy
        statement, the ways in which we collect and use your personal
        information, your choices and rights regarding such use, or wish to
        exercise your rights under California law, please do not hesitate to
        contact us at:{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>.
      </p>

      <h5 className="fw-bold mb-3">Special jurisdictions: Canada</h5>
      <p className="mb-4">Last updated: July 16, 2023</p>

      <h5 className="fw-bold mb-3">About this statement</h5>
      <p className="mb-4">
        Complementing (however, do not substitute) the general Privacy Policy
        provided by NETGET ROUTER Software SAS (“NETGET ROUTER”) and its
        affiliated companies.
      </p>

      <h5 className="fw-bold mb-3">Scope and applicability</h5>
      <p>
        This statement applies to Data Subjects residing in Canada and is
        designed to comply with the Personal Information Protection and
        Electronic Documents Act (“PIPEDA”), and with the Quebec Privacy
        Legislation Modernization Act, also known as Law 25 (“Law 25”).
      </p>

      {/* ------------------- */}

      <h4 className="fw-bold mb-3">Terms and Definitions</h4>
      <p className="mb-4">
        Any terms defined in the Privacy Policy have the same meaning when used
        in this notice. Any terms we use in this Canadian notice that are not
        defined will have the meaning we provide in the Privacy Policy or other
        agreements.
      </p>

      <h4 className="fw-bold mb-3">Collection of Personal Information</h4>
      <p className="mb-4">
        As described in our Privacy Policy, the following data categories are
        collected about you:
      </p>
      <ul className="list-group mb-4">
        <li className="list-group-item">Identifiers (online and physical)</li>
        <li className="list-group-item">Online and network activity</li>
        <li className="list-group-item">Device information</li>
        <li className="list-group-item">Usage information</li>
        <li className="list-group-item">Payment and billing information</li>
        <li className="list-group-item">
          Marketing and advertising information
        </li>
        <li className="list-group-item">
          Customer support and customer relation information
        </li>
      </ul>
      <p className="mb-4">
        For more details about the elements in each category, please refer back
        to our general Privacy Policy.
      </p>

      <h4 className="fw-bold mb-3">Use of Personal Information</h4>
      <p className="mb-4">
        We may use your personal information for one or more of the following
        purposes:
      </p>
      <ul className="list-group mb-4">
        <li className="list-group-item">
          To provide products and services to you
        </li>
        <li className="list-group-item">
          To communicate with you about your orders and shipments
        </li>
        <li className="list-group-item">
          To improve our products and services
        </li>
        <li className="list-group-item">
          To personalize your experience on our website
        </li>
        <li className="list-group-item">
          To send you marketing communications.
        </li>
      </ul>

      <h4 className="fw-bold mb-3">
        Retention and Disposal of Personal Information
      </h4>
      <p className="mb-4">
        In our general privacy policy, we explain the retention schedule of each
        personal data we collect. As a general rule, these are our data
        retention policies:
      </p>
      <ul className="list-group mb-4">
        <li className="list-group-item">
          We retain personal data for as long as is required to establish the
          purpose for which it was collected.
        </li>
        <li className="list-group-item">
          We retain personal data beyond such a period when applicable
          legislation requires us to do so (for example, tax, labor, and so
          forth).
        </li>
        <li className="list-group-item">
          We may retain personal data for a longer period when we want to
          develop and improve our products and services over time. Depending on
          the nature, type and scope of such data, we may employ
          pseudo-anonymization measures to safeguard longer retention periods of
          such data.
        </li>
        <li className="list-group-item">
          Account and subscription information is retained until a valid and
          verified request for deletion is processed.
        </li>
      </ul>
      <p className="mb-4">
        Subject to exemptions, we delete personal data in the following
        circumstances:
      </p>
      <ul className="list-group mb-4">
        <li className="list-group-item">
          Valid and verifiable request to delete.
        </li>
        <li className="list-group-item">
          No longer necessary to serve the purpose for which it was obtained or
          to comply with legal requirements.
        </li>
        <li className="list-group-item">
          If we discover it was unlawfully processed (for example, minors, or if
          a valid consent was not obtained).
        </li>
      </ul>

      {/* ------------------------ */}

      <h4 className="fw-bold mb-3">Third Parties and Data Transfers</h4>
      <p className="mb-4">
        As part of NETGET ROUTER's global operations, we share data with third
        parties, as described in our general privacy policy. This includes
        parties residing outside of Quebec and/or Canada. The following are
        categories of third parties with whom we share personal data. You have
        the right to request to know the name of a specific third party or the
        names under each category.
      </p>
      <ul className="list-group mb-4">
        <li className="list-group-item">
          NETGET ROUTER affiliated companies (such as subsidiaries and parent
          companies)
        </li>
        <li className="list-group-item">
          Hosting services and storage vendors (such as Microsoft, Google, and
          Amazon Web Services)
        </li>
        <li className="list-group-item">
          Analytics, business intelligence, and statistics vendors (such as
          Google Analytics)
        </li>
        <li className="list-group-item">
          Support and customer services (such as ZenDesk)
        </li>
        <li className="list-group-item">
          Compliance vendors (such as OneTrust)
        </li>
        <li className="list-group-item">
          Customer relations management and mailing list (such as Probance)
        </li>
        <li className="list-group-item">
          Billing and payment solutions (such as Upclick)
        </li>
        <li className="list-group-item">
          Advertising and marketing services (such as Google AdWords, AdSense,
          Facebook, LinkedIn)
        </li>
        <li className="list-group-item">
          Technology partners that are integrated into products or websites
          (such as Chip.de and other features)
        </li>
        <li className="list-group-item">
          Special cases (fraud prevention or investigation)
        </li>
      </ul>
      <p className="mb-4">
        For more details about third-party data disclosures, please refer back
        to our general Privacy Policy or contact our DPO at{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>.
      </p>

      <h4 className="fw-bold mb-3">Your Rights</h4>
      <p className="mb-4">
        Subject to certain exemptions provided by law, you may request to
        exercise one or more of the following rights:
      </p>
      <ul className="list-group mb-4">
        <li className="list-group-item">Right to Access</li>
        <li className="list-group-item">Right to Correction</li>
        <li className="list-group-item">Right to Deletion</li>
        <li className="list-group-item">Right to Restriction of Processing</li>
        <li className="list-group-item">Right to Data Portability</li>
        <li className="list-group-item">Right to Object to Processing</li>
        <li className="list-group-item">Right to Complain</li>
      </ul>
      <p className="mb-4">
        For more details about each of these rights and how to exercise them,
        please refer back to our general Privacy Policy or contact us at{" "}
        <a href="mailto:contact@netgenrouter.com">contact@netgenrouter.com</a>.
      </p>

      <h4 className="fw-bold mb-3">Additional Information</h4>
      <p className="mb-4">
        We make every effort to provide a high standard of protection for your
        personal data, including processing in compliance with our policies. Our
        goal is to be transparent about how we process and handle your data, and
        to provide you with the means to manage your privacy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
