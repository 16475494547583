import React from "react";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaComments,
} from "react-icons/fa";
import contactUsImg from "../assets/contact-us-iimg.jpg"

const ContactUs = () => {
  return (
    <div className="container contact-us my-5">
      <h1 className="text-center mb-4 contact-title">Contact Us</h1>

      <div className="row text-center contact-info">
        <div className="col-md-3 contact-box">
          <FaMapMarkerAlt className="contact-icon" />
          <h5>Address</h5>
          <p>
            15001 90th Ave,
            <br /> Jamaica, NY 11432.
          </p>
        </div>
        <div className="col-md-3 contact-box">
          <FaPhoneAlt className="contact-icon" />
          <h5>Phone</h5>
          <p> (888) 788-0417</p>
        </div>
        <div className="col-md-3 contact-box">
          <FaEnvelope className="contact-icon" />
          <h5>Email</h5>
          <p>contact@netgenrouter.com.</p>
        </div>
        <div className="col-md-3 contact-box">
          <FaComments className="contact-icon" />
          <h5>Live Support</h5>
          <p>Available 24/7</p>
        </div>
      </div>

      <div className="row contact-main">
        <div className="col-md-6 d-flex justify-content-center">
          <img
            src={contactUsImg}
            alt="Contact Us"
            className="img-fluid contact-image"
          />
        </div>

        <div className="col-md-6">
          <h2 className="contact-subtitle">Get in Touch</h2>
          <div className="row text-justify additional-info">
            <p>
              Need assistance? Our customer support team is always here to help
              with any questions you may have about our products, services, or
              your order.
            </p>
          </div>
          <form className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter your name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter your email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="message"
                rows="4"
                placeholder="Your message"
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
