import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Common/Navbar";
import Footer from "./components/Common/Footer";
import NeedHelp from "./components/Common/NeedHelp";
import HomePage from "./pages/homePage";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsAndConditions from "./pages/TermsAndConditions";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import NotFound from "./pages/NotFound";
import ScrollToTop from "./components/Common/ScrollToTop";

function App() {
  return (
    <Fragment>
      {/* ------------- */}
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        {/* ------------- */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* ------------- */}
        <Footer />
        <NeedHelp />
      </BrowserRouter>
      {/* ------------- */}
    </Fragment>
  );
}

export default App;
