import React, { Fragment } from "react";

const TermsAndConditions = () => {
  return (
    <Fragment>
      <div className="container policy-container my-5 py-5">
        <h2 className="policy-header fw-bold mb-4">Terms and Conditions</h2>

        <h4 className="fw-bold mb-3">1. Application of the Conditions</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>1.1. Scope.</strong> These general terms and conditions (the
            “Conditions”) apply to all purchases of products or services (the
            “Products”) sold through this website (the “Site”), by us, or
            through our affiliates, as the case may be.
          </li>
          <li className="list-group-item">
            <strong>1.2. ACCEPTANCE.</strong> BY PLACING AN ORDER ON THE SITE
            YOU AGREE TO ABIDE BY THESE CONDITIONS.
          </li>
          <li className="list-group-item">
            <strong>1.3. Legal Capacity.</strong> You represent that you have
            the legal capacity to enter into a contract.
          </li>
          <li className="list-group-item">
            <strong>1.4. Change in Conditions.</strong> We may revise and modify
            the Conditions from time to time without prior notice. The
            Conditions that apply to your purchase are those published on the
            Site at the time of your order.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">
          2. Products, Offer, Acknowledgment, and Acceptance
        </h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>2.1. Product Descriptions.</strong> Product descriptions are
            presented on the Site and can be consulted before your order.
            Photographs and pictures have no contractual value.
          </li>
          <li className="list-group-item">
            <strong>2.2. No Offer.</strong> Any prices, quotations, and
            descriptions made or referred to on the Site do not constitute an
            offer by us but an invitation for you to offer. We may withdraw or
            revise them at any time prior to our express acceptance of your
            order.
          </li>
          <li className="list-group-item">
            <strong>2.3. Choice of Products.</strong> The choice of Products is
            made under your sole responsibility. You are the only person having
            complete knowledge of equipment, software, and configurations that
            you use and are thus the sole judge of the compatibility of the
            Products ordered.
          </li>
          <li className="list-group-item">
            <strong>2.4. Acceptance.</strong> An order submitted by you
            constitutes an offer to purchase Products under these Conditions.
            Your order is subject to our subsequent acceptance.
          </li>
          <li className="list-group-item">
            <strong>2.5. Previous Dispute.</strong> We reserve the right to
            decline any order from a customer with whom there is a dispute
            regarding a previous order.
          </li>
          <li className="list-group-item">
            <strong>2.6. Errors.</strong> We reserve the right to notify you of
            any error relating to a Product prior to dispatch of your order.
          </li>
          <li className="list-group-item">
            <strong>2.7. Availability.</strong> We make every effort to ensure
            that the Products appearing on our Site are available. However, we
            cannot guarantee that all Products are in inventory.
          </li>
          <li className="list-group-item">
            <strong>2.8. Pre-orders.</strong> Release dates for pre-ordered
            Products are subject to supplier information. We are not responsible
            for delays or changes.
          </li>
          <li className="list-group-item">
            <strong>2.9. Archives – Access.</strong> After validation of your
            order, we may keep records of orders and other contract records for
            a reasonable period.
          </li>
        </ul>

        {/* ---------------------- */}

        <h4 className="fw-bold mb-3">3. Price and Payment Terms</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>3.1. Prices.</strong> Prices of Products are exclusive of
            shipping and carriage charges. Promotional prices are only available
            individually and cannot be combined with other offers.
          </li>
          <li className="list-group-item">
            <strong>3.2. Prices modification.</strong> Prices may be modified at
            any time without notice.
          </li>
          <li className="list-group-item">
            <strong>3.3. Taxes.</strong> Prices include applicable taxes. For
            products downloaded from or dispatched outside the European Union,
            tax will be calculated as required.
          </li>
          <li className="list-group-item">
            <strong>3.4. Shipping and Carriage.</strong> If you elect to receive
            a tangible copy of your Product, additional costs for handling,
            shipping, and carriage will apply.
          </li>
          <li className="list-group-item">
            <strong>3.5. Payment.</strong> You shall pay for your order prior to
            delivery using the methods indicated on the Site.
          </li>
          <li className="list-group-item">
            <strong>3.6. Security.</strong> Credit card transactions are secured
            using 128-bit SSL encryption standard.
          </li>
          <li className="list-group-item">
            <strong>3.7. Retention of Title.</strong> Title to Products shall
            not pass until payment has been made in full.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">4. Delivery – Risk</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>4.1. Address of Delivery.</strong> We deliver Products only
            to existing street addresses. You must check the delivery address
            provided and notify us of any errors.
          </li>
          <li className="list-group-item">
            <strong>4.2. Timescales of Delivery.</strong> Delivery timescales
            are estimates only. We cannot be liable for delays.
          </li>
          <li className="list-group-item">
            <strong>4.3. Risk of Loss.</strong> Risk of loss or damage to the
            Products passes to you on delivery.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">5. Download and Back-Up</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>5.1. Download Procedure.</strong> After payment, you will
            receive an email with a link to download the Product.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">6. Support</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>6.1. Availability.</strong> Technical support varies
            according to the Products and countries, as detailed in Product
            Description pages.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">7. Statutory Cancellation Right</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>7.1. Consumer Cancellation Right.</strong> As a consumer,
            you have the right to cancel your order within seven (7) days after
            delivery.
          </li>
          <li className="list-group-item">
            <strong>7.2. Exercise of Cancellation Right.</strong> To exercise
            your right of cancellation, contact us during the Cancellation
            Period.
          </li>
          <li className="list-group-item">
            <strong>7.3. Refunds.</strong> We will refund any sum paid in
            connection with your cancelled order within thirty (30) days.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">8. Satisfaction Guarantee</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>8.1. Scope.</strong> You may cancel your order within thirty
            (30) days of delivery under our Satisfaction Guarantee policy.
          </li>
          <li className="list-group-item">
            <strong>8.2. Exercise of Satisfaction Guarantee.</strong> To
            exercise your right under the satisfaction guarantee, contact us
            during the Contractual Period.
          </li>
          <li className="list-group-item">
            <strong>8.3. Refunds.</strong> Refunds will be processed within
            forty (40) days of receiving your cancellation notice.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">9. Limited Guarantee</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>9.1. Scope of Guarantee.</strong> We guarantee software
            mediums for a fair period not exceeding thirty (30) days from
            receipt.
          </li>
          <li className="list-group-item">
            <strong>9.2. Limitations of Guarantee.</strong> The guarantee does
            not apply if defects result from unauthorized repairs or misuse.
          </li>
          <li className="list-group-item">
            <strong>9.3. Exclusions of Guarantees.</strong> We do not offer any
            other guarantees, whether express or implied.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">10. Returns of Products</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>10.1. Procedure.</strong> Contact Customer Service to return
            Products under statutory rights or satisfaction guarantee.
          </li>
          <li className="list-group-item">
            <strong>10.2. Duty of Care.</strong> You must not use Products and
            must take reasonable care of them in case of cancellation.
          </li>
          <li className="list-group-item">
            <strong>10.3. Costs of Returning Products.</strong> Costs of
            returning Products are your responsibility in case of cancellation.
          </li>
          <li className="list-group-item">
            <strong>10.4. Risk.</strong> Risk of loss or damage to returned
            Products passes to us only upon receipt.
          </li>
        </ul>

        {/* ------------------------ */}

        <h4 className="fw-bold mb-3">11. Data Protection</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>11.1. Data Collection Policies.</strong> Refer to our
            Privacy Policy for details on data collection, disclosure, and use.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">12. Liability Limitation</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>12.1. Death, Personal Injury, Negligence.</strong> We do not
            exclude or restrict liability for death or personal injury resulting
            from our negligence.
          </li>
          <li className="list-group-item">
            <strong>12.2. Foreseeable Losses.</strong> We are only liable for
            losses which are foreseeable as a consequence of our breach or
            negligence.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">13. Software Programs</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>13.1. Licensing Terms.</strong> Software is licensed by the
            relevant licensor and subject to end-user license agreements.
          </li>
          <li className="list-group-item">
            <strong>13.2. Copyright.</strong> Software may not be copied,
            modified, or reverse-engineered except as permitted by licensing
            terms or law.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">14. General</h4>
        <ul className="list-group mb-4">
          <li className="list-group-item">
            <strong>14.1. Consumer Law.</strong> These Conditions do not affect
            statutory rights under applicable consumer protection laws.
          </li>
          <li className="list-group-item">
            <strong>14.2. Assignment.</strong> You are not entitled to assign
            your rights under these Conditions to third parties.
          </li>
          <li className="list-group-item">
            <strong>14.3. Severability.</strong> Invalid provisions do not
            affect the validity of the remaining provisions.
          </li>
          <li className="list-group-item">
            <strong>14.4. Governing Law.</strong> The Conditions and the sale
            are governed by the laws of the jurisdiction where you reside.
          </li>
        </ul>

        <h4 className="fw-bold mb-3">15. Contact</h4>
        <p className="mb-1">
          For any questions or concerns, please contact us at:
        </p>
        <p className="mb-0">
          Customer Service:{" "}
          <a href="mailto:contact@netgetrouter.com">contact@netgetrouter.com</a>
        </p>
        <p className="mb-4">
          Phone: <a href="tel:+18887880417">(888) 788-0417</a>
        </p>
      </div>
    </Fragment>
  );
};

export default TermsAndConditions;
