import React, { Fragment } from "react";
import LandingBanner from "../components/LandingBanner";
import TopProducts from "../components/TopProducts";
import FeaturesBox from "../components/FeaturesBox";
import AllProducts from "../components/AllProducts";
import CallBanner from "../components/CallBanner";

const HomePage = () => {
  return (
    <Fragment>
      <LandingBanner />
      <TopProducts />
      <FeaturesBox />
      <AllProducts />
      <CallBanner />
    </Fragment>
  );
};

export default HomePage;
