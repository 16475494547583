import React from "react";
import { FaPhone, FaMapMarkerAlt, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-container text-white">
      <div className="container">
        <div className="row">
          {/* Policies Section */}
          <div className="col-md-4 col-sm-12 mb-2">
            <h5>Policies</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/privacy-policy" >
                  Privacy Policy
                </Link>
              </li>

              <li>
                <Link to="/terms-and-conditions" >
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>

          {/* About Us & Contact Us Section */}
          <div className="col-md-4 col-sm-12 mb-4">
            <h5>Company</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/about-us">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Details Section */}
          <div className="col-md-4 col-sm-12">
            <h5>Contact Info</h5>
            <ul className="list-unstyled">
              <li>
                <FaPhone className="me-2" />{" "}
                <a href="tel:(888) 788-0417">
                  (888) 788-0417
                </a>
              </li>
              <li>
                <FaMapMarkerAlt className="me-2" /> 15001 90th Ave, Jamaica, NY
                11432.
              </li>
              <li>
                <FaEnvelope className="me-2" />{" "}
                <a
                  href="mailto:contact@netgenrouter.com"
                  
                >
                  contact@netgenrouter.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
