import React from "react";
import { FaTruck, FaDollarSign, FaHeadset } from "react-icons/fa";

const FeaturesBox = () => {
  const features = [
    { id: 1, icon: <FaTruck />, text: "Free Delivery" },
    { id: 2, icon: <FaDollarSign />, text: "Low Prices Guaranteed" },
    { id: 3, icon: <FaHeadset />, text: "24/7 Support" },
  ];
  return (
    <div className="container-fluid all-bg-color  my-4">
      <div className="container">
        <div className="row d-flex justify-content-center">
          {features.map((feature) => (
            <div
              key={feature.id}
              className="col-md-3 feature-div border rounded shadow mx-2 py-4 col-sm-6 col-12 d-flex justify-content-center align-items-center mb-3"
            >
              <div className="icon-container">{feature.icon}</div>
              <span className="feature-text ms-2">{feature.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesBox;
