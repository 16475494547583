import React from "react";

const CallBanner = () => {
  return (
    <div className="container parallax-container my-5">
      <div className="parallax-banner-content">
        <h4>Limited Time Offer</h4>
        <h2>Save Upto $100</h2>
        <h4>On selected Router, Extenders &amp; Orbi models</h4>
        <div className="mt-4">
          <a href="tel:(888) 788-0417" className="shop-now-btn">
            Shop Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default CallBanner;
