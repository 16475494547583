import React from "react";
import bannerImg from "../assets/banner-img.png";

const LandingBanner = () => {
  return (
    <div className="landing-banner container-fluid d-flex align-items-center">
      <div className="row w-100">
        {/* Left Column with Text Boxes */}
        <div className="col-md-6 col-sm-12 d-flex flex-column align-items-center justify-content-center">
          <div className="text-box mb-3">Router</div>
          <div className="text-box mb-3">Extender</div>
          <div className="text-box mb-3">NightHawk</div>
          <div className="text-box mb-3">Orbi</div>
        </div>

        {/* Right Column with Image */}
        <div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
          <img src={bannerImg} alt="Banner-Image" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default LandingBanner;
