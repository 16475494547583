import React from "react";
import routerImg2 from "../assets/router2-img.webp";
import ExtenderImg2 from "../assets/extender2-img.webp";
import orbiImg2 from "../assets/orbi2-img.webp";
import nighthawkImg from "../assets/nightwalk-img.webp";
import nighthawkImg2 from "../assets/nighthawk2-img.webp";
import lightImg from "../assets/orbi2-img.webp";

const AllProducts = () => {
  const allProducts = [
    {
      title: "Orbi",
      image: orbiImg2,
      alt: "Orbi",
    },
    {
      title: "Router",
      image: routerImg2,
      alt: "Router",
    },
    {
      title: "Extender",
      image: ExtenderImg2,
      alt: "Extender",
    },
    {
      title: "Nighthawk DOCSIS 3.1",
      image: nighthawkImg,
      alt: "Nighthawk DOCSIS 3.1",
    },
    {
      title: "Nighthawk DOCSIS 3.1",
      image: nighthawkImg2,
      alt: "Nighthawk DOCSIS 3.1",
    },
    {
      title: "Light",
      image: lightImg,
      alt: "Light",
    },
  ];
  return (
    <div className="container my-5">
      <h2 className="text-center mb-5 fw-bold">ROUTERS, EXTENDERS & ORBI</h2>
      <div className="row">
        {allProducts.map((product, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card product-card">
              <div className="d-flex justify-content-center">
                <img
                  src={product.image}
                  className=" product-image"
                  alt={product.alt}
                  // style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div className="card-body text-center mb-4">
                <h5 className="product-card-title">{product.title}</h5>
                <a href="tel:(888) 788-0417" className="shop-now-btn">
                  Buy Now
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllProducts;
