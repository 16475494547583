import React from "react";
import routerImg from "../assets/router-img.png";
import ExtenderImg from "../assets/extenders-img.png";
import orbiImg from "../assets/orbi-img.png";

const TopProducts = () => {
  const products = [
    {
      id: 1,
      name: "Routers",
      image: routerImg,
    },
    {
      id: 2,
      name: "Extenders",
      image: ExtenderImg,
    },
    {
      id: 3,
      name: "Orbi",
      image: orbiImg,
    },
  ];
  return (
    <div className="container my-4">
      <div className="row">
        {products.map((product) => (
          <div key={product.id} className="col-md-4 col-sm-6 col-12 mb-4">
            <div className="card h-100 text-center product-card">
              <div className="d-flex justify-content-center">
                <img
                  src={product.image}
                  className="product-image"
                  alt={product.name}
                />
              </div>
              <div className="card-body mb-4">
                <h5 className="product-card-title">{product.name}</h5>
                <a href="tel:(888) 788-0417" className="shop-now-btn">
                  Show Now
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopProducts;
