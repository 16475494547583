// AboutUs.js
import React from "react";
import ourStoryImg from "../assets/our-story-img.jpg";
import ourMissionImg from "../assets/our-mission-img.jpg";

function AboutUs() {
  return (
    <div className="container about-container my-5">
      <div className="about-header">
        <h1>About NetGen Router</h1>
        <p>Your Gateway to Advanced Networking Solutions</p>
      </div>

      <div className="about-content row">
        <div className="col-md-6 d-flex justify-content-center">
          <img
            src={ourStoryImg}
            alt="Our Story"
            className="img-fluid about-image"
          />
        </div>
        <div className="col-md-6">
          <h2 className="about-section-title">Our Story</h2>
          <p className="section-text">
            NetGen Router was founded with a vision to bridge the gap between
            seamless connectivity and robust security for both home and
            enterprise networks. Over the years, we have evolved into a leader
            in the networking industry, delivering innovative and
            high-performance router solutions designed for today’s connected
            world. As the internet continues to transform our lives, NetGen
            Router is committed to creating products that not only keep you
            connected but also safeguard your data.
          </p>
          <p className="section-text">
            Our journey began with a small team of technology enthusiasts who
            recognized the challenges people face with unreliable networks and
            complicated setups. Today, NetGen Router is synonymous with
            user-friendly, powerful, and adaptable solutions that empower our
            customers to experience a truly connected world. We are proud to
            serve a diverse customer base ranging from individual users to large
            organizations, each trusting NetGen Router to meet their unique
            networking needs.
          </p>
        </div>
      </div>

      <div className="about-content row">
        <div className="col-md-6">
          <h2 className="about-section-title">Our Mission</h2>
          <p className="section-text">
            At NetGen Router, our mission is to revolutionize the networking
            experience by delivering innovative, secure, and reliable products
            that make connectivity easier for everyone. We believe that everyone
            deserves access to high-quality network solutions that offer both
            speed and stability without compromising security.
          </p>
          <p className="section-text">
            With a focus on research and development, we continuously push the
            boundaries of what's possible in network technology. Our team works
            tirelessly to incorporate the latest advancements in hardware and
            software to ensure our products meet the demands of today’s digital
            landscape. From ensuring compatibility with the latest smart home
            devices to enhancing security protocols, we aim to provide routers
            that grow and evolve with your needs.
          </p>
          <p className="section-text">
            Our mission extends beyond just providing routers; we are here to
            empower users by simplifying network management. We envision a
            future where connecting to the world is as effortless as flipping a
            switch. With NetGen Router, you get a partner committed to helping
            you achieve that vision, ensuring your online experiences are fast,
            safe, and truly connected.
          </p>
        </div>
        <div className="col-md-6 d-flex justify-content-center">
          <img src={ourMissionImg} alt="Our Mission" className="about-image" />
        </div>
      </div>

      <div className="about-content">
        <h2 className="about-section-title text-center">Our Values</h2>
        <div className="values">
          <div className="value-card">
            <h3>Innovation</h3>
            <p>
              Leading the way in network technology with forward-thinking
              solutions.
            </p>
          </div>
          <div className="value-card">
            <h3>Quality</h3>
            <p>
              Providing robust, reliable routers designed to meet your needs.
            </p>
          </div>
          <div className="value-card">
            <h3>Customer Commitment</h3>
            <p>
              Ensuring the highest level of service and support to our
              customers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
