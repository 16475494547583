import React from "react";
import logoImg from "../../assets/logo-img.png";

const Navbar = () => {
  return (
    <nav className="navbar fixed-top custom-navbar">
      <div className="container-fluid">
        <a href="/" className="navbar-brand">
          <img src={logoImg} alt="Logo" className="logo" />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
